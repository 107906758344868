// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Place any jQuery/helper plugins in here.

/**
 * Based on https://gist.githubusercontent.com/JeffreyWay/5112282/raw/454bc5f65a8190818b4d8e4cc7797523fe096546/laravel.js
 */
(function($, undefined) {
    function handleMethod(e) {
        var link = $(this);
        var httpMethod = link.data('method').toUpperCase();
        var form;

        // If the data-method attribute is not PUT or DELETE,
        // then we don't know what to do. Just ignore.
        if ( $.inArray(httpMethod, ['PUT', 'POST', 'DELETE']) === - 1 ) {
            return;
        }

        // Allow user to optionally provide data-confirm="Are you sure?"
        if ( link.data('confirm') ) {
            if ( ! verifyConfirm(link) ) {
                return false;
            }
        }

        form = createForm(link);
        form.submit();

        e.preventDefault();
    }

    function verifyConfirm(link) {
        return confirm(link.data('confirm'));
    }

    function buildUrl(link) {
        var url = link.attr('href');
        return url;
    }

    function createForm(link) {
        var form = $('<form>', {
            'method': 'POST',
            'action': buildUrl(link),
        });

        var token = $('<input>', {
            'type': 'hidden',
            'name': 'csrfmiddlewaretoken',
            'value': Cookies.get('csrftoken')
        });

        var hiddenInput = $('<input>', {
            'name': '_method',
            'type': 'hidden',
            'value': link.data('method')
        });

        return form.append(token, hiddenInput)
                   .appendTo('body');
    }

    $(function() {
        $(document).on('click', 'a[data-method]', handleMethod);
    });

})(jQuery);
