jQuery(function($, undefined) {
    var started = false;

    $('.top-trigger, .menu-backdrop').on('click', function () {
        var $body = $('body');

        if (!started && ($('.side-menu-area').css('left') != '0px')) {
            $body.removeClass('side-menu-start-open');
        }

        if ($body.hasClass('side-menu-open') || $body.hasClass('side-menu-start-open')) {
            $body.removeClass('side-menu-open');
        } else {
            $body.addClass('side-menu-open');
        }

        $body.removeClass('side-menu-start-open');
        started = true;
    });

    $('.menu-start-open-toggle').on('click', function (e) {
        var $body = $('body');
        var $icon = $(this).find('i');

        e.preventDefault();

        if (Cookies.get('side_menu') == 'open') {
            $body.removeClass('side-menu-open');
            $icon.removeClass('fa-chevron-circle-left').addClass('fa-chevron-circle-right');
            Cookies.set('side_menu', 'closed');
        } else {
            $body.addClass('side-menu-open');
            $icon.removeClass('fa-chevron-circle-right').addClass('fa-chevron-circle-left');
            Cookies.set('side_menu', 'open');
        }

        $body.removeClass('side-menu-start-open');
    });
});
